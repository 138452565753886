import * as React from "react";
import { BasicLayout } from "../../components/layout";
import SEO from "../../components/seo/seo";
import LoadingState from "../../components/ui-library/loading-state/loading-state";
import { useGetAuthToken } from "../../hooks/use-get-auth-token";
import TopicsLanding from "../../components/internal/topics-landing/topics-landing";
import SignInNudge from "../../components/internal/sign-in-nudge/sign-in-nudge";

const TopicsPage: React.FC = () => {
  const { loading, userToken } = useGetAuthToken();

  if (loading) {
    return (
      <BasicLayout>
        <LoadingState />
      </BasicLayout>
    );
  }

  if (!userToken) {
    return (
      <BasicLayout>
        <SignInNudge />
      </BasicLayout>
    );
  }

  return (
    <BasicLayout>
      <SEO title="All topics" meta={[{ name: "robots", content: "noindex" }]} />

      <TopicsLanding />
    </BasicLayout>
  );
};

export default TopicsPage;

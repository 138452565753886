import * as React from "react";
import { Link } from "gatsby";
import ContentCard from "../../ui-library/content-card/content-card";
import PageContainer from "../../ui-library/page-container/page-container";
import Container from "../../ui-library/container/container";
import CardContent from "../../ui-library/content-card/card-content";
import PageHeading from "../../ui-library/page-heading/page-heading";
import { RoundButton } from "../../design-tokens/button";

const SignInNudge: React.FC = () => {
  return (
    <PageContainer>
      <PageHeading>
        Sorry, you can't see this page if not signed in.
      </PageHeading>
      <Container>
        <ContentCard>
          <CardContent>
            <div css={{ display: "flex", justifyContent: "center" }}>
              <Link to="/internal/sign-in/" css={RoundButton}>
                Sign in
              </Link>
            </div>
          </CardContent>
        </ContentCard>
      </Container>
    </PageContainer>
  );
};

export default SignInNudge;

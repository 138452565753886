import * as React from "react";
import PageContainer from "../../ui-library/page-container/page-container";
import PageHeading from "../../ui-library/page-heading/page-heading";
import Container from "../../ui-library/container/container";
import Row from "../../ui-library/column-system/row";
import Column from "../../ui-library/column-system/column";
import QuestionCard from "../../ui-library/question-card/question-card";
import { EventCategory } from "../../../utils/analytics";

const TopicsLanding: React.FC = () => {
  return (
    <PageContainer>
      <PageHeading>Select a topic to edit</PageHeading>
      <Container>
        <Row>
          <Column spanLg={6} spanMd={6} spanSm={6} spanXs={12}>
            <QuestionCard
              url="/internal/covid-19/all-intents"
              name="COVID-19"
              eventCategory={EventCategory.Internal}
            />
          </Column>
          <Column spanLg={6} spanMd={6} spanSm={6} spanXs={12}>
            <QuestionCard
              url="/internal/blockchain/all-intents"
              name="Blockchain"
              eventCategory={EventCategory.Internal}
            />
          </Column>
        </Row>
      </Container>
    </PageContainer>
  );
};

export default TopicsLanding;

import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import { initialzeFirebase } from "../core/initialize-firebase";

export const useGetAuthToken = () => {
  const [userToken, setUserToken] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    let userTimeOut: NodeJS.Timeout;
    if (window) {
      initialzeFirebase();

      let counter = 0;
      const getToken = () => {
        const user = firebase.auth().currentUser;
        if (user) {
          user
            .getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
              setUserToken(idToken);
              setLoading(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          if (counter < 10) {
            userTimeOut = setTimeout(() => {
              getToken();
              counter += 1;
            }, 200);
          } else {
            setLoading(false);
          }
        }
      };

      getToken();
    }
    return () => {
      clearTimeout(userTimeOut);
    };
  }, []);

  return { loading, userToken };
};

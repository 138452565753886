import { Interpolation } from "@emotion/core";
import { grid } from "./grid";
import { Fonts } from "./fonts";
import { Colors } from "./colors";
import { Border } from "./borders";
import { DarkTheme } from "./media-queries";

export const Button = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  WebkitAppearance: "none",
  height: grid(5),
  fontSize: "16px",
  fontWeight: Fonts.Weight.SemiBold,
  border: `1px solid ${Colors.BorderGrey}`,
  borderRadius: Border.RadiusSm,

  [DarkTheme]: {
    border: `1px solid ${Colors.DarkModeBorder}`,
  },
};

export const RoundButton: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  WebkitAppearance: "none",
  minWidth: grid(18),
  height: grid(5),
  fontSize: "18px",
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.White,
  backgroundColor: Colors.Blue,
  borderRadius: grid(5),

  ":hover": {
    textDecoration: "none",
  },
};
